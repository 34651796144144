import React from 'react';
import Modal from './Modal';
import styles from './styles/Loader.module.css';
import Header from './Header';

const Loader = ({ title }) => {
    return (
      <Modal>
          <div className={styles.loaderContainer}>
              <Header header={title}/>
              <div className={styles.customLoader}></div>
          </div>
      </Modal>
    );
};

export default Loader;
