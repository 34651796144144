import Modal from '../components/Modal';
import React from 'react';
import buttonClasses from '../components/styles/ButtonsStyle.module.css';
import classes from './styles/Error.module.css'
import { useNavigate } from "react-router-dom";



const Error =({message = "OOppsss....", onDone, redirectToMainPage = false})=>{
    const navigate = useNavigate();
    const onDoneHandle = () =>{
        if(redirectToMainPage){
            navigate("/");
        } else {
            onDone()
        }
    }


    return(
      <Modal title="Щось трапилось" onClose={onDone}>
          <div className={classes.errorMessage}> {message} </div>

          <p className={buttonClasses.actions}>
              <button
                className={buttonClasses.addButton}
                type="button" onClick={onDoneHandle}

              >
                  Закрити
              </button>
          </p>
      </Modal>
    )
};

export default Error;
