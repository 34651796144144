import {sendRequest} from './https';
import {endPoints} from '../variables/endPoints';
import { baseUrl } from '../env/env';

export const tokenLoader = async () =>{
    try {
        const res = await sendRequest('get', baseUrl + endPoints.refresh, {}, {});
        return res.data.access_token
    } catch {
        return null;
    }
};
