import React, { useContext, useEffect, useState } from 'react';
import MainNavigation from '../components/MainNavigation';
import classes from './styles/RootLayout.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import { SpicesContext } from '../store/spices-context';
import { Outlet } from 'react-router-dom';
import { UserContext } from '../store/user-context';
import Button from '../components/Button';
import Error from './Error';
import { useNavigate, useLoaderData } from "react-router-dom";
import Loader from '../components/Loader';

function RootLayout () {
    const token = useLoaderData();

    const navigate = useNavigate();
    const {clearSpiceStorage, fetchAllStatuses} = useContext(SpicesContext);
    const { setUser, isLoggedIn, logoutUser, refreshUserToken  } = useContext(UserContext);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const closeError = ()=>{
        setError(false)
    };



    const handleLogout = async () =>{
        const hasError = await logoutUser();
        if (hasError){
            setError(hasError)
        } else {
            navigate("/auth");
            clearSpiceStorage()
        }

    };

    const fetchAllSpices = async () => {
        setIsLoading(true);
       let response = await fetchAllStatuses(token);

        if(response.hasError){
            setError(response.message);
        } else {
            if(response.token){
                refreshUserToken(response.token)
            }
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (token !== null){
            setUser(token, true);
            fetchAllSpices();
        } else {
            navigate("/auth");
        }
        // Skip useEffect 'fetchAllSpices' dependency and eslint check for react-hooks/exhaustive-deps
        // to avoid infinite loop.
        // eslint-disable-next-line
    }, [token, navigate]);

    return (
      <>
          <AnimatePresence>
              {error && <Error message={error} onDone={closeError}/>}
          </AnimatePresence>
          <AnimatePresence>
              {isLoading && <Loader title="Зачекайте, будь ласка...."/>}
          </AnimatePresence>

          <div className={classes.rootLayoutContainer}>
              {isLoggedIn &&
              <motion.div
                variants={{
                    hidden: { opacity: 0, y: -40 },
                    visible: { opacity: 1, y: 0 }
                }}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className={classes.headerContainer}
              >
                  <div className={classes.header}>Наші спеції</div>
                  <Button onClick={handleLogout} title="Вийти"/>
              </motion.div>
              }

              <div className={classes.rootLayout}>
                  {isLoggedIn && <MainNavigation/> }

                  <main>
                      <Outlet/>
                  </main>
              </div>
          </div>

     </>

    );

}

export default RootLayout;
