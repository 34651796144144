import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router/index';
import './App.css';
import UserContextProvider from './store/user-context';
import SpicesContextProvider from './store/spices-context';

function App () {

    return (
      <UserContextProvider>
          <SpicesContextProvider>
              <RouterProvider router={router}>

              </RouterProvider>
          </SpicesContextProvider>
      </UserContextProvider>
    );
}

export default App;
