import React, { useContext, useRef, useState } from 'react';
import Modal from './Modal';

import buttonClasses from "./styles/ButtonsStyle.module.css"
import inputClasses from './styles/InputClasses.module.css';
import SpiceStatusesTab from './SpiceStatusesTab';
import DeleteSpice from './DeleteSpice';
import { AnimatePresence } from 'framer-motion';
import { SpicesContext } from '../store/spices-context';
import { UserContext } from '../store/user-context';
import Error from '../pages/Error';



const EditSpice =({item, onDone, onHandleEdit})=>{
    const { deleteSpice } = useContext(SpicesContext);
    const name = useRef();
    const [selectedStatus , setSelectedStatus ] = useState(item.status)

    const handleSelectStatus = (status)=>{
        setSelectedStatus(status);
    };

    const handleEdit = ()=>{
        onHandleEdit(name.current.value, selectedStatus);
    };
    const [showDeleteSpice, setDeleteSpice] = useState(false);
    const showDelete = () => {
        setDeleteSpice(true);
    };
    const handleCloseDelete = () => {
        setDeleteSpice(false);
    };
    const handleDeleteSpice = async () => {
        let response = await deleteSpice(token, item.id, item.status);

        if (response.hasError) {
            setError(response.message);
        } else {
            if(response.token){
                refreshUserToken(response.token)
            }
            onDone();
        }
        setDeleteSpice(false);

    };
    const [error, setError] = useState(false);
    const handleCloseError = () => {
        setError(false);
    };
    const {token, refreshUserToken} = useContext(UserContext);

    return(
      <Modal title="Оновити" onClose={onDone}>
          <AnimatePresence>
              {error &&
              <Error
                onDone={handleCloseError}
                message={error}/>
              }
          </AnimatePresence>
          <AnimatePresence>
              {showDeleteSpice &&
              <DeleteSpice
                onDone={handleCloseDelete}
                onHandleDelete={handleDeleteSpice}
                item={item}/>
              }
          </AnimatePresence>
          <div>
              <p>
                  <label className={inputClasses.label} htmlFor="name">Назва</label>
                  <input className={inputClasses.input}  ref={name} type="text" name="name" id="name" defaultValue={item.name}/>
              </p>
             <SpiceStatusesTab id="editStatuses" defaultStatus={item.status} onSelectStatus={handleSelectStatus}/>

          </div>
          <div>
              <div className={buttonClasses.outerContainer}>
              <button onClick={showDelete}
                className={buttonClasses.deleteButton}>
                  Видалити
              </button>
              <div className={buttonClasses.actions}>

                  <button
                    className={buttonClasses.cancelButton}
                    type="button" onClick={onDone}>
                      Відмінити
                  </button>
                  <button
                    onClick={handleEdit}
                    className={buttonClasses.addButton}>
                      Змінити спецію
                  </button>
              </div>
              </div>
          </div>

      </Modal>
    )
}
export default EditSpice;
