import React from 'react';
import Image from './Image';
import { statuses } from '../variables/statuses';
import classes from './styles/FoundSpices.module.css'

const FoundSpicesList = ({ items, onFoundItemClick }) => {
    const clickHandle = (item)=>{
        onFoundItemClick(item);
    }
    return (
      <div>
          {items.map(item => (
            <div
              onClick={()=>{clickHandle(item)}}
              className={classes.foundSpiceContainer}
              key={item.id}
            >
                <Image
                  alt={ `${item.status} foundSpice`}
                  src={statuses.find(status => (status.value === item.status)).src}
                />
                <p>{item.name}</p>
            </div>
          ))}
      </div>
    );
};

export default FoundSpicesList;
