import classes from './styles/Button.module.css';
import { motion } from 'framer-motion';


const Button = ({title, onClick}) =>{
    return(
      <motion.button
        whileHover={{scale: 1.1, /*backgroundColor: '#8b11f0'*/}}
        transition = {{type:'spring', stiffness: 500}}
        className={classes.button}
          onClick={onClick}>
          {title}
      </motion.button>
    )
};

export default Button;
