import Badge from './Badge';
import classes from './styles/MenuItem.module.css'

const MenuItem = ({title, amount}) =>{
    return (
      <div className={classes.menuItem}>
          <div className={classes.title}>{title}</div>
          <Badge caption={amount}/>
      </div>
    )
};

export default MenuItem;
