import {motion} from'framer-motion';
import classes from './styles/Badge.module.css'
export default function Badge({ caption, active = false }) {
    return <motion.span
      data-testid = "badge"
      animate={{scale: [1, 1.2, 1]}}
      transition = {{duration: 0.3}}
      className={`${classes.badge} ${active ? classes.active : null}`}>
        {caption}
    </motion.span>;
}
