import classes from './styles/SpiceStatuses.module.css';
import { statuses } from '../variables/statuses';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

const SpiceStatusesTab = ({defaultStatus, id, onSelectStatus}) =>{
    const [selectedStatus, setSelectedStatus] = useState(defaultStatus);
    const handleSelectStatus = (status)=>{
        setSelectedStatus(status);
        onSelectStatus(status);
    };
    return(
      <motion.ul
        variants={{
            visible: { transition: { staggerChildren: 0.05 } }  //to control the animation of children // staggerChildren : 0.05ms delay ofeveryitem animation
        }}
        className={classes['spice-statuses']}
        id={id}>
          {statuses.map((status) => (
            <motion.li

              variants={{
                  hidden: { opacity: 0, scale: 0.5 },
                  visible: { opacity: 1, scale: [0.8, 1.3, 1] }
              }}
              transition={{ type: 'spring' }}
              exit={{ opacity: 1, scale: 1 }}

              key={status.value }
              onClick={() => handleSelectStatus(status.value)}
              className={`${selectedStatus=== status.value ? classes.selected : ''} ${classes.li} ${status.value}` }
            >
               <img
                  className={classes.image}
                  src={status.src} alt={status.value}/>

            </motion.li>
          ))}
      </motion.ul>
    )
};

export default SpiceStatusesTab;
