import React, { useContext, useEffect, useState } from 'react';
import PageContainer from '../components/PageContainer';
import { SpicesContext } from '../store/spices-context';
import SpicesList from '../components/SpicesList';
import Header from '../components/Header';
import Error from './Error';
import { AnimatePresence } from 'framer-motion';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import { UserContext } from '../store/user-context';

const AllSpices = () => {
    const { spices, fetchSpices, allCounts, pageSize } = useContext(SpicesContext);
    const {refreshUserToken, token} = useContext(UserContext);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageAmount = Math.ceil(allCounts / pageSize);
    const handleChangePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const closeError = () => {
        setError(false);
    };


    const fetchAllSpices = async () => {
        setIsLoading(true);

        if(token){
            let allSpices = await fetchSpices(token, 'all', currentPage);
            if(allSpices.hasError){
                setError(allSpices.message);
            }
            if(allSpices.token){
                refreshUserToken(allSpices.token);
            }
        }


        setIsLoading(false);
    };
    useEffect(() => {
        fetchAllSpices();
        // Skip useEffect 'fetchAllSpices' dependency and eslint check for react-hooks/exhaustive-deps
        // to avoid infinite loop.
        // eslint-disable-next-line
    }, [currentPage]);

    return (

          <PageContainer>
              <AnimatePresence>
                  {isLoading && <Loader title="Зачекайте, будь ласка..."/>}
              </AnimatePresence>
              <AnimatePresence>
                  {error && <Error message={error} onDone={closeError}/>}
              </AnimatePresence>
              {spices.length > 0 && <div>
                  <Header header="Усі спеції"/>

                  <SpicesList
                    showStatus={true}
                    currentPage = {currentPage}
                    items={spices}/>
                  {pageAmount > 1 && <Pagination
                    currentPage={currentPage}
                    pageAmount={pageAmount}
                    onChangePage={handleChangePage}
                  />}
              </div>}

              {spices.length === 0 && <Header header="Список порожній"/>}

          </PageContainer>
    );
};

export default AllSpices;
