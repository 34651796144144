import axios from 'axios';
import { endPoints } from '../variables/endPoints';
import { baseUrl, apiVersion } from '../env/env';

export const sendRequest = async (method, url, token, payload = {}, contentType = 'application/json') => {
    let oUrl = new URL(url);
    const uri = oUrl.pathname;
    if (uri !== '/login' && uri !== '/refresh' && uri !== '/logout') {
        oUrl.pathname = '/' + apiVersion.replaceAll('/', '') + uri;
    }
    const res = await axios({
        method,
        url: oUrl.href,
        data: payload,
        headers: {
            'Content-Type': contentType,
            Authorization: 'Bearer ' + token
        },
        withCredentials: true
    });

    return res;
};

export const sendRequestWithTokenCheck = async (method, url, token, payload) => {
    try {
        const res = await sendRequest(method, url, token, payload);
        return res;
    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            try {
                const newToken = await sendRequest('post', baseUrl + endPoints.refresh, {}, {});
                const res = await sendRequest(method, url, newToken.data.token, payload);
                let resWithToken = { data: res.data };
                res.token = newToken.data.access_token;
                return resWithToken;
            } catch (error) {
                let isError = {
                    hasError: true,
                    message: error.message ? error.message : 'Something went wrong. Try again later'
                };
                return isError;
            }
        }
        let isError = {
            hasError: true,
            message: error.message ? error.message : 'Something went wrong. Try again later'
        };
        return isError;
    }
};

