import { motion } from 'framer-motion';
import classes from './styles/PageContainer.module.css';
import React from 'react';

const PageContainer = ({ children }) => {
    return (

          <motion.div
            className={classes.pageContainer}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ y: -30, opacity: 0 }}
          >
              {children}
          </motion.div>

)
};

export default PageContainer;
