import { createContext, useState } from 'react';
import axios from 'axios';
import { ENV, baseUrl } from '../env/env';
import {sendRequest} from '../utils/https';
import {endPoints} from '../variables/endPoints';

export const UserContext = createContext({
    isLoggedIn: '',
    token: '',
    loginUser: ()=>{},
    logoutUser : ()=>{},
    setUser : ()=>{},
    registerUser: ()=>{},
    refreshUserToken : ()=>{}

});

export default  function UserContextProvider ({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState('');


    const refreshUserToken = (token)=>{
        setToken(token)

    };
    const setUser = (token, status)=>{
        setToken(token);
        setIsLoggedIn(status);
    };

    const logoutUser = async ()=>{

        let responseError = false;

        if(ENV === "DEV"){
            setUser('', false);
            return responseError;
        }

        try{
            await sendRequest("get", baseUrl + endPoints.logout, token, null);
            setUser('', false);
        } catch (e){
            responseError = e.message ? e.message : "Sorry something went wrong"
        }
        return responseError;
    };
    const loginUser = async (username, password) => {
        let response = false;

        try {
            const res = await sendRequest(
                "post",
                baseUrl + endPoints.login,
                null,
                { username: username, password: password },
                'application/json',
            );
            setUser(res.data.access_token, true);

        } catch (e) {
            response = e.message ? e.message : 'Try again later';
        }
        return response;
    };

    const registerUser = async (email, password) => {
        let response = false;
        try {
            await axios.post(
              baseUrl + 'register',
              { email: email, password: password },
              {
                  headers: {
                      'Content-Type': 'application/json',
                  }
              }
            );
        } catch (e) {
            response = e.message ? e.message : 'Try again later';
        }
        return response;
    };



    const userContext = {
        isLoggedIn,
        token,
        loginUser,
        setUser,
        logoutUser,
        registerUser,
        refreshUserToken


    };

    return (
      <UserContext.Provider value={userContext}>
          {children}
      </UserContext.Provider>
    );
}
