import { createContext, useState } from 'react';
import { baseUrl } from '../env/env';
import { FULL, OUT_OF_STOCK, RUNNING_OUT } from '../variables/statuses';
import { sendRequest, sendRequestWithTokenCheck } from '../utils/https';
import { endPoints } from '../variables/endPoints';

export const SpicesContext = createContext({
    spices: [],
    full: [],
    runningOut: [],
    outOfStock: [],
    allCounts: 0,
    fullCounts: 0,
    runningOutCounts: 0,
    outOfStockCounts: 0,
    pageSize: 50,
    addSpices: () => {},
    updateSpice: () => {},
    deleteSpice: () => {},
    fetchSpices: () => {},
    fetchAllStatuses: () => {},
    findSpices: () => {},
    changeStatuses: () => {},
    clearSpiceStorage : ()=>{}
});

export default function SpicesContextProvider ({ children }) {
    const pageSize = 5;
    const [spices, setSpices] = useState([]);

    const [full, setFull] = useState([]);
    const [runningOut, setRunningOut] = useState([]);
    const [outOfStock, setOutOfStock] = useState([]);

    const [allCounts, setAllCounts] = useState(0);
    const [fullCounts, setFullCounts] = useState(0);
    const [runningOutCounts, setRunningOutCounts] = useState(0);
    const [outOfStockCounts, setOutOfStockCounts] = useState(0);

    const clearSpiceStorage = ()=>{
        setSpices([]);
        setFull([]);
        setRunningOut([]);
        setOutOfStock([]);
        setAllCounts(0);
        setFullCounts(0);
        setRunningOutCounts(0);
        setOutOfStockCounts(0);
    };

    const fetchAllStatuses = async (token, page = 1, onPage = pageSize) => {
        let hasError;
        hasError = await fetchSpices(token, 'all', page);
        hasError = await fetchSpices(token, FULL, page);
        hasError = await fetchSpices(token, RUNNING_OUT, page);
        hasError = await fetchSpices(token, OUT_OF_STOCK, page);

        return hasError;
    };

    const fetchSpices = async (token, status, page = 1 , perPage = pageSize) => {

        let returnedResponse = {
            hasError: false,
          //  token: "RefreshedToken"
        };

        let query = `?page=${page}&perPage=${perPage}`;
        let url = baseUrl + 'spice/list/';
        if (status !== 'all') {
            url = url + `${status}`;
        }
        url = url + query;
        const response = await sendRequestWithTokenCheck('get', url, token, null);


        if (response.hasError) {
            returnedResponse = response;
        } else {
            let data = response.data;
            if(response.token){
                returnedResponse.token = response.token;
            }

                if (status === 'all') {
                    setSpices(data.items);
                    setAllCounts(data.total);
                }
                if (status === FULL) {
                    setFull(data.items);
                    setFullCounts(data.total);
                }
                if (status === RUNNING_OUT) {
                    setRunningOut(data.items);
                    setRunningOutCounts(data.total);
                }
                if (status ===  OUT_OF_STOCK) {
                    setOutOfStock(data.items);
                    setOutOfStockCounts(data.total);
                }
        }
        return returnedResponse;

    };

    const addSpice = async (token, spice) => {
        let returnedResponse = {
            hasError: false,
        };

        const response = await sendRequestWithTokenCheck("post", baseUrl + endPoints.spice, token, spice);

        let newToken;
        if (response.hasError){
            return response
        } else {
            let data = response.data;
            if(response.token){
                newToken = response.token;
            }
            returnedResponse = await fetchSpices(token, 'all');
            returnedResponse = await fetchSpices(token, data.status);
            if(newToken){
                returnedResponse.token = newToken;
            }
        }
        return returnedResponse;

    };

    const updateSpice = async (token, spice, method, currentPage) => {

        let returnedResponse = {
            hasError: false,
        };
        let oldStatus = null;
        if (spice.oldStatus) {
            oldStatus = spice.oldStatus;
            delete (spice.oldStatus);
        }

        let newToken;

        const response = await sendRequestWithTokenCheck(method.toLowerCase(), baseUrl + endPoints.spice, token, spice);

        if (response.token){
            newToken = response.token
        }
        if(!response.hasError){
            if (oldStatus) {
                returnedResponse = await fetchSpices(newToken ? newToken : token, oldStatus, currentPage);
            }
            returnedResponse = await fetchSpices(newToken ? newToken : token,'all', currentPage);
            returnedResponse = await fetchSpices(newToken ? newToken : token, response.data.status, currentPage);
        } else {
            return response;
        }
        return returnedResponse;
    };

    const deleteSpice = async (token, id, status) => {

        let returnedResponse;

        const response = await sendRequestWithTokenCheck("delete", baseUrl + endPoints.spice + `/${id}`, token, {});

        if(response.hasError){
            return response
        } else {
            let newToken;
            if (response.token){
                newToken = response.token
                returnedResponse.token = newToken;
            }
            returnedResponse = await fetchSpices(newToken ? newToken: token, 'all');

            returnedResponse = await fetchSpices(newToken? newToken: token, status);

        }

        return returnedResponse;
    };

    const findSpices = async (token, query) => {
        let returnedResponse = {};
        let q = `?q=${query}`;

        const response = await sendRequest("get", baseUrl +'spice/list' + q, token, {});


        if(response.hasError){
            return response
        } else {
            returnedResponse.data = response.data.items
            if (response.token){
                returnedResponse.token = response.token
            }
        }
        return returnedResponse;
    };

    const changeStatuses = async (token, status, ids) => {
        let returnedResponse;
        const response = await sendRequestWithTokenCheck("patch", baseUrl + endPoints.spices, token, {status: status, ids: ids});

        if(response.hasError){
            return response
        } else {
            let newToken;
            if (response.token){
                newToken = response.token
            }
            returnedResponse = await fetchSpices(newToken? newToken: token, 'all');
            returnedResponse = await fetchSpices(newToken? newToken: token, status);
        }



        return returnedResponse;
    };

    const spicesContext = {
        spices,
        full,
        runningOut,
        outOfStock,
        allCounts,
        fullCounts,
        runningOutCounts,
        outOfStockCounts,
        pageSize,
        addSpice,
        updateSpice,
        deleteSpice,
        fetchSpices,
        fetchAllStatuses,
        findSpices,
        changeStatuses,
        clearSpiceStorage

    };

    return (
      <SpicesContext.Provider value={spicesContext}>
          {children}
      </SpicesContext.Provider>
    );
}
