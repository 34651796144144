import React, { useContext, useRef, useState } from 'react';
import { SpicesContext } from '../store/spices-context';

import Modal from './Modal.js';

import { useAnimate, stagger, AnimatePresence } from 'framer-motion';
import buttonClasses from './styles/ButtonsStyle.module.css';
import inputClasses from './styles/InputClasses.module.css';
import SpiceStatusesTab from './SpiceStatusesTab';
import Error from '../pages/Error';
import { UserContext } from '../store/user-context';

export default function NewSpices ({ onDone }) {
    const { addSpice } = useContext(SpicesContext);
    const {token, refreshUserToken} = useContext(UserContext);

    const name = useRef();
    const [scope, animate] = useAnimate(); // scope - is the ref,to which can be added to element to scope the element NOT to select elements allover the page . animate- is the function, which can be imperatively trigger certain animation

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [error, setError] = useState(false);
    const closeError = () =>{
        setError(false)
    }

    function handleSelectStatus (status) {
        setSelectedStatus(status);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const spice = {
            name: name.current.value,
            status: selectedStatus,
        };

        if (
          !spice.name.trim() ||
          !spice.status
        ) {
            animate('input, textarea, #new-spice-statuses',   // has 3 argument. 1 - HTML elements or css classes, 2 - object where animation is described (the sameobject which is used for animation. 3 - configuration object (transition)
              { y: [-10, 0, 10, 0] },
              { type: 'spring', duration: 0.2, delay: stagger(0.05) }
            );
            return;
        }

        const response = await addSpice(token, spice);
        if (response.hasError) {
            setError(response.message);
            return;
        } else {
            if(response.token){
                refreshUserToken(response.token)
            }
            onDone();
        }
    };

    return (
      <>
          <AnimatePresence>
              {error && <Error message={error} onDone={closeError}/>}
          </AnimatePresence>
          <Modal title="Додати спецію" onClose={onDone}>
              <form
                ref={scope}
                onSubmit={handleSubmit}>
                  <p>
                      <label className={inputClasses.label} htmlFor="name">Назва</label>
                      <input className={inputClasses.input} ref={name} type="text" name="name" id="name"/>
                  </p>

                  <label className={inputClasses.label} htmlFor="spice-statuses">Статус</label>
                  <SpiceStatusesTab id="newStatuses" defaultStatus={null} onSelectStatus={handleSelectStatus}
                                    keyIndex={1}/>
                  <p className={buttonClasses.actions}>
                      <button
                        className={buttonClasses.cancelButton}
                        type="button" onClick={onDone}>
                          Відмінити
                      </button>
                      <button
                        onClick={handleSubmit}
                        className={buttonClasses.addButton}>
                          Додати спецію
                      </button>
                  </p>
              </form>
          </Modal>
      </>
    );
}
