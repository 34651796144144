import React from 'react';
import Login from '../components/Login';

const Auth = () => {

    return (
      <>
        <Login/>
      </>
    );
};

export default Auth;
