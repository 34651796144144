import classes from '../pages/styles/Login.module.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import inputClasses from './styles/InputClasses.module.css';
import Modal from './Modal';
import buttonClasses from './styles/ButtonsStyle.module.css';
import UserContextProvider, { UserContext } from '../store/user-context';
import { AnimatePresence } from 'framer-motion';
import Loader from './Loader';
import Error from '../pages/Error';
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const userName = useRef();
    const password = useRef();
    const {loginUser} = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const {token} = useContext(UserContext);


    const login = async () => {
        setIsLoading(true);
        if (userName.current.value === '')
        {
            setError("Поле імені не може бути пустим. Перевірте, будь ласка, введені дані");
            setIsLoading(false);
            return
        }

        if(password.current.value === ''){
            setError("Введіть, будь ласка, пароль");
            setIsLoading(false);
            return
        }
        const response = await loginUser(userName.current.value , password.current.value );

        if (response) {
            setError(response);
        } else {
            navigate("/");
        }
        setIsLoading(false);
    };
    const closeError = () => {
        setError(false);
    };

    useEffect(()=>{
        if(token){

            navigate("/outOfStock");
        }
    }, [token, navigate]);

    const onFormHandle = (ev) =>{

        if(ev.keyCode === 13){
            login();
        }
    };



    return (
      <UserContextProvider>
          <AnimatePresence>
              {isLoading && <Loader title="Зачекайте, будь ласка...."/>}
          </AnimatePresence>
          <AnimatePresence>
              {error && <Error message={error} onDone={closeError}/>}
          </AnimatePresence>
          <Modal title="Увійти">
              <div className={classes.loginContainer} >
                  <div>
                      <div>
                          <p>
                              <label className={inputClasses.label} htmlFor="name">Ім'я</label>
                              <input
                                className={inputClasses.input}
                                ref={userName}
                                type="text"
                                name="userName"
                                id="userName"
                                onKeyUp={(event)=>{onFormHandle(event)}}
                              />
                          </p>

                          <p>
                              <label className={inputClasses.label} htmlFor="name">Пароль</label>
                              <input
                                className={inputClasses.input}
                                ref={password}
                                type="password"
                                name="password"
                                id="password"
                                onKeyUp={(event)=>{onFormHandle(event)}}
                              />
                          </p>

                          <p className={buttonClasses.actions}>
                              <button
                                className={buttonClasses.addButton}
                                type="button" onClick={login}>
                                  Увійти
                              </button>

                          </p>


                      </div>
                  </div>
              </div>
          </Modal>
      </UserContextProvider>

    );
};

export default Login;
