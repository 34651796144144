import { createPortal } from 'react-dom';
import {motion} from 'framer-motion'
import classes from './styles/Modal.module.css'

export default function Modal({ title, children, onClose }) {
    //const hiddenAnimationState ={ opacity: 0, y: 30}

    //variants={{ }} key-values pairs can be used in animation
  return createPortal(
    <>
      <div className={classes.backdrop} onClick={onClose} />
      <motion.dialog open className={classes.modal}
                     variants={{
                         hidden: { opacity: 0, y: 30},
                         visible : { opacity: 1, y: 0}
                     }}
                     initial = "hidden"
                     animate ="visible"
                     exit = "hidden"

      >
        <h2>{title}</h2>
        {children}
      </motion.dialog>
    </>,
    document.getElementById('modal')
  );
}
