import React from 'react';
import classes from './styles/SpicesList.module.css';
import { motion } from 'framer-motion';
import SpiceItem from './SpiceItem';

const SpicesList = ({ items, currentPage, displayFullAllButton = false, showStatus = false}) => {

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.15
            }
        }
    };

    const listItem = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    };

    return (
      <>
          {items &&
          <motion.ul variants={container} initial="hidden" animate="show" className={classes.list}>
              {items.map(item => (
                <motion.li
                  variants={listItem}
                  key={item.id}
                  exit={{ opacity: 1, scale: 1 }} // do NOT use the name of variants
                >
                   <SpiceItem
                     showStatus={showStatus}
                     currentPage = {currentPage}
                     item={item}
                     displayFullAllButton = {displayFullAllButton}
                   />
                </motion.li>
              ))}

          </motion.ul>}
      </>
    );
};

export default SpicesList;
