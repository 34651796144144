import Badge from './Badge';
import classes from './styles/PaginationItem.module.css';
import { motion } from 'framer-motion';
import React from 'react';

const PaginationItem = ({ number, active, onChangePage }) => {

    return (
      <motion.div
        whileHover={{scale: 1.3, }}
        transition = {{type:'spring', stiffness: 500}}
        className={classes.paginationItem}
        onClick ={()=>{onChangePage(number)}}
      >
          <Badge caption={number} active={active}/>
    </motion.div>

    );
};

export default PaginationItem;
