import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { SpicesContext } from '../store/spices-context';

import classes from './styles/MainNavigation.module.css';
import MenuItem from './MenuItem';
import Button from './Button';
import NewSpices from './NewSpices';
import Error from '../pages/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import FindSpices from './FindSpices';
import { UserContext } from '../store/user-context';


const MainNavigation = () => {
   const {isLoggedIn} = useContext(UserContext);
    const { allCounts, fullCounts, runningOutCounts, outOfStockCounts, fetchAllStatuses} = useContext(SpicesContext);
    const menuItems = [
        { title: 'Усі спеції', route: '/', counts: allCounts, key: 1 },
        { title: 'Наявні', route: '/available', counts: fullCounts, key: 2 },
        { title: 'Закінчуються', route: '/runningOut', counts: runningOutCounts, key: 3 },
        { title: 'Купити', route: '/outOfStock', counts: outOfStockCounts, key: 4 },
    ];
    const {token} = useContext(UserContext);

    const [error, setError] = useState(false);

    const [isCreatingNewSpices, setIsCreatingNewSpices] = useState(false);
    const [showFind, setShowFind] = useState(false);

    const closeError = () => {
        setError(false);
    };

    const onClickHandler = () => {
        setIsCreatingNewSpices(true);
    };

    const handleDone = () => {
        setIsCreatingNewSpices(false);
    };
    const handleCloseFind = () => {
        setShowFind(false);
    };
    const fetchAllSpices = async () => {
        await fetchAllStatuses(token);
    };

    useEffect(() => {
        fetchAllSpices();
        // Skip useEffect 'fetchAllSpices' dependency and eslint check for react-hooks/exhaustive-deps
        // to avoid infinite loop.
        // eslint-disable-next-line
    }, []);

    return (
      <>
          <AnimatePresence>
              {isCreatingNewSpices && <NewSpices onDone={handleDone}/>}
          </AnimatePresence>
          <AnimatePresence>
              {showFind && <FindSpices onDone={handleCloseFind}/>}
          </AnimatePresence>

          <AnimatePresence>
              {error && <Error message={error} onDone={closeError}/>}
          </AnimatePresence>

          {isLoggedIn && <motion.div
            variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { opacity: 1, y: 0 }
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={classes.menuContainer}>
              <nav>
                  <ul
                    className={classes.list}
                  >
                      {menuItems.map(menuItem => (
                        <li key={menuItem.key}>
                            <NavLink
                              to={menuItem.route}
                              className={({ isActive }) =>
                                isActive ? classes.active : ''
                              }
                            >
                                <MenuItem
                                  title={menuItem.title}
                                  amount={menuItem.counts}
                                />
                            </NavLink>
                        </li>
                      ))}
                  </ul>
              </nav>

              <div className={classes.buttonContainer}>
                  <motion.div
                    whileHover={{ scale: 1.3, /*backgroundColor: '#8b11f0'*/ }}
                    transition={{ type: 'spring', stiffness: 500 }}
                  >
                      <FontAwesomeIcon
                        icon={faSearch}
                        className={classes.icon}
                        onClick={() => {setShowFind(true);}}
                      />
                  </motion.div>

                  <Button
                    title="+"
                    onClick={onClickHandler}/>
              </div>
          </motion.div>
          }
      </>
    );
};

export default MainNavigation;
