import PageContainer from '../components/PageContainer';
import React, { useContext, useEffect, useState } from 'react';
import { SpicesContext } from '../store/spices-context';
import Header from '../components/Header';
import SpicesList from '../components/SpicesList';
import { RUNNING_OUT } from '../variables/statuses';
import { AnimatePresence } from 'framer-motion';
import Loader from '../components/Loader';
import Error from './Error';
import Pagination from '../components/Pagination';
import { UserContext } from '../store/user-context';

const RunningOut = () => {

    const { runningOut, fetchSpices, pageSize, runningOutCounts } = useContext(SpicesContext);
    const {refreshUserToken, token} = useContext(UserContext);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageAmount = Math.ceil(runningOutCounts / pageSize);
    const handleChangePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const closeError = () => {
        setError(false);
    };
    const fetchRunningOutSpices = async () => {
        setIsLoading(true);
        if (token) {
            let runningOutSpices = await fetchSpices(token, RUNNING_OUT, currentPage);
            if (runningOutSpices.hasError) {
                setError(runningOutSpices.message);
            }
            if (runningOutSpices.token) {
                refreshUserToken(runningOutSpices.token)
            }
        }
        setIsLoading(false);
    };
    useEffect(() => {
        fetchRunningOutSpices();
        // Skip useEffect 'fetchRunningOutSpices' dependency and eslint check for react-hooks/exhaustive-deps
        // to avoid infinite loop.
        // eslint-disable-next-line
    }, [currentPage]);

    return (
      <PageContainer>
          <AnimatePresence>
              {isLoading && <Loader title="Зачекайте, будь ласка...."/>}
          </AnimatePresence>
          <AnimatePresence>
              {error && <Error message={error} onDone={closeError}/>}
          </AnimatePresence>
          {runningOut.length >0 && <div>
              <Header header="На один раз достатньо"/>

              <SpicesList
                showStatus={true}
                currentPage = {currentPage}
                items={runningOut}/>
              {pageAmount > 1 && <Pagination
                currentPage={currentPage}
                pageAmount={pageAmount}
                onChangePage={handleChangePage}
              />}
          </div>}
          {runningOut.length === 0 && <Header header = "Список порожній"/>}
      </PageContainer>
    );
};

export default RunningOut;
