import React, { useContext, useState } from 'react';
import classes from './styles/SpiceItem.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import EditSpice from './EditSpice';

import { SpicesContext } from '../store/spices-context';
import Error from '../pages/Error';
import DeleteSpice from './DeleteSpice';

import { UserContext } from '../store/user-context';
import {  FULL, OUT_OF_STOCK, RUNNING_OUT, statusesImg, updateStatusesIcons} from '../variables/statuses';


const SpiceItem = ({item, displayFullAllButton,currentPage, showStatus=false}) => {

    let src = statusesImg[item.status];

    const statusImage =  <img
                    className={classes.image}
                    src={src} alt={item.status}/>

    const {token, refreshUserToken} = useContext(UserContext);

    const { updateSpice, deleteSpice } = useContext(SpicesContext);
    const [error, setError] = useState(false);
    const handleCloseError = () => {
        setError(false);
    };
    const [showEditSpice, setEditSpice] = useState(false);
    const showEdit = () => {
        setEditSpice(true);
    };
    const handleCloseEdit = () => {
        setEditSpice(false);
    };

    const handleEditSpice = async (newName, newStatus) => {
        let response = {hasError : false};
        if (newName === item.name && newStatus === item.status) {
            handleCloseEdit();
        } else {
            response = await updateSpice(token, { id: item.id, name: newName, status: newStatus, oldStatus : item.status}, 'PUT', currentPage);
        }
        if (response.hasError) {
            setError(response.errorMessage);
        } else {
            if(response.token){
                refreshUserToken(response.token)
            }
        }
        setEditSpice(false);
    };

    const [showDeleteSpice, setDeleteSpice] = useState(false);

    const handleCloseDelete = () => {
        setDeleteSpice(false);
    };
    const handleDeleteSpice = async () => {
        let response = await deleteSpice(token, item.id, item.status);

        if (response.hasError) {
            setError(response.message);
        } else {
            if(response.token){
                refreshUserToken(response.token)
            }
        }
        setDeleteSpice(false);

    };
    const handleUpdateStatus = async(oldStatus, newStatus) => {
       let response = await updateSpice(token, { id: item.id, oldStatus: oldStatus, status: newStatus }, 'PATCH');
        if (response.hasError) {
            setError(response.message);
        } else {
            if(response.token){
                refreshUserToken(response.token)
            }
        }
    };

    return (
      <>

          <AnimatePresence>
              {error &&
              <Error
                onDone={handleCloseError}
                message={error}/>
              }
          </AnimatePresence>
          <AnimatePresence>
              {showEditSpice &&
              <EditSpice
                onDone={handleCloseEdit}
                onHandleEdit={handleEditSpice}
                item={item}/>
              }
          </AnimatePresence>
          <AnimatePresence>
              {showDeleteSpice &&
              <DeleteSpice
                onDone={handleCloseDelete}
                onHandleDelete={handleDeleteSpice}
                item={item}/>
              }
          </AnimatePresence>
          <div className={classes.spiceItem}>
              {showStatus && statusImage}
              {!showStatus && <div></div>}

              <motion.h4 whileHover={{ scale: 1.01 }}
                         transition={{ type: 'spring', stiffness: 500 }}
                         onClick={showEdit}>{item.name}
              </motion.h4>


              <div className={classes.actions}>

                  {item.status !== FULL && <motion.span
                    whileHover={{ scale: 1.1}}
                    transition={{ type: 'spring', stiffness: 500 }}
                    onClick = {()=>{handleUpdateStatus(item.status, FULL)}}
                    className={classes.updateStatus}
                  >
                      <img alt='toFull'
                        src={updateStatusesIcons.full}/>
                  </motion.span>}
                  {item.status !== RUNNING_OUT && <motion.span
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: 'spring', stiffness: 500 }}
                    onClick = {()=>{handleUpdateStatus(item.status, RUNNING_OUT)}}
                    className={classes.updateStatus}
                  >
                      <img alt='toRunningOut'
                        src={updateStatusesIcons.running_out}/>
                  </motion.span>}
                  {item.status !== 'outOfStock' && <motion.span
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: 'spring', stiffness: 500 }}
                    onClick = {()=>{handleUpdateStatus(item.status, OUT_OF_STOCK)}}
                    className={classes.updateStatus}
                  >
                      <img alt='toOutOfStock'
                        src={updateStatusesIcons.out_of_stock}/>

                  </motion.span>}
              </div>


          </div>
      </>
    );
};

export default SpiceItem;
