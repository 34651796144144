import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import AllSpices from '../pages/AllSpices';

import RootLayout from '../pages/RootLayout';
import OutOfStock from '../pages/OutOfStock';
import RunningOut from '../pages/RunningOut';
import Available from '../pages/Available';
import { tokenLoader } from '../utils/auth';
import Auth from '../pages/Auth';
import Error from '../pages/Error';

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout/>,
        loader: tokenLoader,
        id: 'root',
        errorElement: <Error redirectToMainPage={true}/>,
        children: [
            {
                index: true,
                element: <AllSpices/>,
            },
            {
                path: '/available',
                element: <Available/>,
            },
            {
                path: '/outOfStock',
                element: <OutOfStock/>,
            },

            {
                path: '/runningOut',
                element: <RunningOut/>,
            },
            {
                path: 'auth',
                element: <Auth/>,
            },

        ],
    },
]);

export default router;
