import fullImg from '../assets/full.png';
import runningOutImg from '../assets/runningOut.png';
import outOfStockImg from '../assets/outOfStock.png';

import fullIcon from '../assets/menu-3.png';
import runningOutICon from '../assets/menu-2.png';
import outOfStockIcon from '../assets/menu-1.png';

export const FULL = 'full';
export const RUNNING_OUT = 'runningOut';
export const OUT_OF_STOCK = 'outOfStock';
export const statusesArray = [FULL, RUNNING_OUT, OUT_OF_STOCK];

export const statuses = [
    { value: FULL, name: 'Наявні' , src: fullImg},
    { value: RUNNING_OUT, name: 'Закінчується' , src: runningOutImg},
    { value: OUT_OF_STOCK, name: 'Купити', src : outOfStockImg },
];

export const updateStatusesIcons ={
    full : fullIcon,
    running_out: runningOutICon,
    out_of_stock : outOfStockIcon
}



export const statusesImg = {
    full: fullImg,
    runningOut: runningOutImg,
    outOfStock: outOfStockImg
}
