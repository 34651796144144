import React, { useState } from 'react';
import PaginationItem from './PaginationItem';
import classes from './styles/Pagination.module.css'

const Pagination = ({currentPage, pageAmount, onChangePage}) =>{
    const pageNumbers = [...Array(pageAmount).keys()].map(item=>(item + 1 ));
    const [page, setPage] = useState(currentPage);

    const handleChangePage =(selectedPage) => {
        setPage(selectedPage);
        onChangePage(selectedPage);
    };
    return(
      <div className={classes.paginationContainer}>
          {pageNumbers.map(number =>(
            <PaginationItem
              key={number}
              number={number}
              active={page === number}
              onChangePage = {handleChangePage}
            />
          ))}
      </div>
    )
};

export default Pagination;
