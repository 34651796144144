import PageContainer from '../components/PageContainer';
import React, { useContext, useEffect, useState } from 'react';
import { SpicesContext } from '../store/spices-context';
import SpicesList from '../components/SpicesList';
import Header from '../components/Header';
import { FULL } from '../variables/statuses';
import { AnimatePresence } from 'framer-motion';
import Loader from '../components/Loader';
import Error from './Error';
import Pagination from '../components/Pagination';
import { UserContext } from '../store/user-context';

const Available = () => {
    const {refreshUserToken, token} = useContext(UserContext);
    const { full, fetchSpices, pageSize, fullCounts } = useContext(SpicesContext);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageAmount = Math.ceil(fullCounts / pageSize);
    const handleChangePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const closeError = () => {
        setError(false);
    };

    const fetchFullSpices = async () => {
        setIsLoading(true);
        if (token){
            let fullSpices = await fetchSpices(token, FULL, currentPage);
            if (fullSpices.hasError){
                setError(fullSpices.message);
            }
            if(fullSpices.token){
                refreshUserToken(fullSpices.token)
            }
        }


        setIsLoading(false);
    };
    useEffect(() => {
        fetchFullSpices();
        // Skip useEffect 'fetchFullSpices' dependency and eslint check for react-hooks/exhaustive-deps
        // to avoid infinite loop.
        // eslint-disable-next-line
    }, [currentPage]);

    return (
      <PageContainer>
          <AnimatePresence>
              {isLoading && <Loader title="Зачекайте, будь ласка...."/>}
          </AnimatePresence>
          <AnimatePresence>
              {error && <Error message={error} onDone={closeError}/>}
          </AnimatePresence>
          {full.length >0 && <div>
              <Header header="Можна варити"/>

              <SpicesList
                showStatus={true}
                currentPage = {currentPage}
                items={full}/>
              {pageAmount > 1 && <Pagination
                currentPage={currentPage}
                pageAmount={pageAmount}
                onChangePage={handleChangePage}
              />}
          </div>}

          {full.length === 0 && <Header header = "Список порожній"/>}

      </PageContainer>
    );
};

export default Available;
