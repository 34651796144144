import Modal from './Modal';
import React from 'react';
import buttonClasses from './styles/ButtonsStyle.module.css';
import generalClasses from '../assets/styles/generalStyles.module.css'

const DeleteSpice = ({ item, onDone, onHandleDelete }) => {
   const handleDelete = () =>{
       onHandleDelete()
   };

    return(
      <Modal title="Видалити спецію" onClose={onDone}>
          <h3 className={generalClasses.fontBlue}>Ви дійснохочете видалити {item.name}?</h3>
          <div>
              <p className={buttonClasses.actions}>
                  <button
                    className={buttonClasses.cancelButton}
                    type="button" onClick={onDone}>
                      Відмінити
                  </button>
                  <button
                    onClick={handleDelete}
                    className={buttonClasses.addButton}>
                      Видалити спецію
                  </button>
              </p>
          </div>
      </Modal>
)};

export default DeleteSpice;
