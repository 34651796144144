import PageContainer from '../components/PageContainer';
import React, { useContext, useEffect, useState } from 'react';
import { SpicesContext } from '../store/spices-context';
import Header from '../components/Header';
import SpicesList from '../components/SpicesList';
import { FULL, OUT_OF_STOCK } from '../variables/statuses';
import { AnimatePresence } from 'framer-motion';
import Loader from '../components/Loader';
import Error from './Error';
import Pagination from '../components/Pagination';
import Button from '../components/Button';
import classes from './styles/OutOfStock.module.css'
import { UserContext } from '../store/user-context';

const OutOfStock = () => {

    const {refreshUserToken, token} = useContext(UserContext);
    const { outOfStock, fetchSpices, outOfStockCounts, pageSize, changeStatuses } = useContext(SpicesContext);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageAmount = Math.ceil(outOfStockCounts / pageSize);
    const handleChangePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const closeError = () => {
        setError(false);
    };
    const handleFullAll = async () => {
        let ids = [];
        outOfStock.forEach(item => {
            ids.push(item.id);
        });
        let response = await changeStatuses(token, FULL, ids);
        if (response.hasError) {
            setError(response.message);
        } else {
            await fetchSpices(token, OUT_OF_STOCK, currentPage);
            await fetchSpices(token, FULL, currentPage);
            if(response.token){
                refreshUserToken(response.token)
            }
        }
    };

    const fetchOutOfStockSpices = async () => {
        setIsLoading(true);
        if (token) {
            let outOfStockSpices = await fetchSpices(token, OUT_OF_STOCK, currentPage);
            if (outOfStockSpices.hasError) {
                setError(outOfStockSpices.message);
            }

            if (outOfStockSpices.token) {
                refreshUserToken(outOfStockSpices.token);
            }
        }
        setIsLoading(false);
    };
    useEffect(() => {
        fetchOutOfStockSpices();
        // Skip useEffect 'fetchOutOfStockSpices' dependency and eslint check for react-hooks/exhaustive-deps
        // to avoid infinite loop.
        // eslint-disable-next-line
    }, [currentPage]);

    return (
      <PageContainer>
          <AnimatePresence>
              {isLoading && <Loader title="Зачекайте, будь ласка...."/>}
          </AnimatePresence>
          <AnimatePresence>
              {error && <Error message={error} onDone={closeError}/>}
          </AnimatePresence>
          {outOfStock.length > 0 && <div>
              <div className={classes.headerContainer}>
                  <Header header="Терміново в магазин"/>
                  <Button title="Наповнити всі" onClick={handleFullAll}/>
              </div>
              <SpicesList
                showStatus={true}
                currentPage = {currentPage}
                items={outOfStock}
                displayFullAllButton={true}
              />
              {pageAmount > 1 && <Pagination
                currentPage={currentPage}
                pageAmount={pageAmount}
                onChangePage={handleChangePage}
              />}

          </div>}
          {outOfStock.length === 0 && <Header header="Список порожній"/>}
      </PageContainer>
    );
};

export default OutOfStock;
