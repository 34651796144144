import Modal from './Modal';
import React, { useContext, useEffect, useState } from 'react';
import buttonClasses from './styles/ButtonsStyle.module.css';
import inputClasses from './styles/InputClasses.module.css';
import { SpicesContext } from '../store/spices-context';
import { AnimatePresence } from 'framer-motion';
import Error from '../pages/Error';
import FoundSpicesList from './FoundSpicesList';
import { UserContext } from '../store/user-context';
import EditSpice from './EditSpice';


const FindSpices = ({ onDone, item }) => {
    const {token, refreshUserToken} = useContext(UserContext);
    const { findSpices, updateSpice } = useContext(SpicesContext);
    const [query, setQuery] = useState('');
    const handleChange = (e) => {
        setQuery(e.target.value);
    };
    const [error, setError] = useState(false);
    const closeError = ()=>{
        setError(false)
    };
    const [foundSpices, setFoundSpices] = useState([]);

    const find = async () => {
        const res = await findSpices(token, query);
        if (res.hasError) {
            setError(res.errorMessage);
        } else {
            if(res.token){
                refreshUserToken(res.token)
            }
            setFoundSpices(res.data);
        }
    };
    const handleEditSpice = async (newName, newStatus) => {
        let response = {hasError : false};
        if (newName === editItem.name && newStatus === editItem.status) {
            handleCloseEdit();
        } else {
            response = await updateSpice(token, { id: editItem.id, name: newName, status: newStatus, oldStatus : editItem.status}, 'PUT', 1);
        }
        if (response.hasError) {
            setError(response.errorMessage);
        } else {
            if(response.token){
                refreshUserToken(response.token)
            }
        }
        setShowEditSpice(false);
        find()
        //onDone();
    };


    const [showEditSpice, setShowEditSpice] = useState(false);
    const handleCloseEdit = () => {
        find();
        setShowEditSpice(false);
    };
    const [editItem, setEditItem] = useState({})
    const handleFoundItemClick = (item)=>{
        setEditItem(item);
        setShowEditSpice(true);
    }

    useEffect(() => {
        if (query) {
            find();
        } else {
            setFoundSpices([]);
        }
        // Skip useEffect 'find' dependency and eslint check for react-hooks/exhaustive-deps
        // to avoid infinite loop.
        // eslint-disable-next-line
    }, [query]);
    return (
      <>
          <AnimatePresence>
              {error && <Error message={error} onDone={closeError}/>}
          </AnimatePresence>
          <AnimatePresence>
              {showEditSpice &&
              <EditSpice
                onDone={handleCloseEdit}
                onHandleEdit={handleEditSpice}
                item={editItem}/>
              }
          </AnimatePresence>

          <Modal title="Знайти спеції" onClose={onDone}>

              <p>
                  <label className={inputClasses.label} htmlFor="query">Назва</label>
                  <input
                    autoFocus
                    placeholder="Пошук..."
                    value={query}
                    onChange={handleChange}
                    className={inputClasses.input}
                    type="text"
                    name="query"
                    id="query"/>
              </p>

              {foundSpices.length > 0 &&
                <FoundSpicesList
                  items={foundSpices}
                  onFoundItemClick = {handleFoundItemClick}
                />
              }

              <p className={buttonClasses.actions}>
                  <button
                    className={buttonClasses.addButton}
                    type="button" onClick={onDone}>
                      Закрити
                  </button>

              </p>
          </Modal>
      </>
    );
};

export default FindSpices;
